import { useState, useContext, memo, useRef, useEffect, useCallback, useMemo } from 'react';
import {
  Box, Link, TextField, IconButton, List, ListItem, ListItemAvatar,
  CircularProgress, Avatar, ListItemText, Typography, Stack, Chip, Icon
} from '@mui/material';
import axios from 'axios';
import { getApiRoute } from 'src/services';
import Navbar from './blocks/Navbar.js';
import { AuthContext } from 'src/utils/AuthContext.js';
import { CalendarToday } from '@mui/icons-material';
import { useProfileImage } from 'src/utils/useProfileImage.js';
import { formatSubscribers } from 'src/utils/Utils.js';
import { useIndexedDB } from 'src/utils/useIndexedDB.js';
import SubscribeButton from './blocks/SubscribeButton.js';
import alfie from 'src/assets/alfie.png';
import { Link as RouterLink, useLocation } from "react-router-dom";
import { useIsMounted } from 'src/utils/Utils.js';
import { useFullHeightPageFix } from 'src/utils/useFullHeightPageFix.js';
import { Helmet } from 'react-helmet';
import { useTheme } from '@emotion/react';

const MAX_MESSAGES = 15;

// Component to render the calendar avatar
const CalendarAvatar = memo(({ calendar }) => {
  const { handle, name } = calendar;
  const profileImage = useProfileImage({ picture: `${process.env.REACT_APP_CLOUD_STORAGE_IMAGE_URL}/${handle}-profileImage.png` });
  return (
    <Avatar alt={name} src={profileImage}>
      <CalendarToday />
    </Avatar>
  )
}, (prevProps, nextProps) => prevProps.calendar._id === nextProps.calendar._id && 
                          prevProps.calendar.name === nextProps.calendar.name && 
                          prevProps.calendar.handle === nextProps.calendar.handle && 
                          prevProps.calendar.subscribers === nextProps.calendar.subscribers);

// Function to create the Google Form feedback URL
const createFeedbackUrl = (searchTerm, handles, userEmail) => {
  const baseUrl = 'https://docs.google.com/forms/d/e/1FAIpQLSd-tKnYFXkQS0c0EJtByLCLDPTvFNDRRKHt7TIC_-mVYz5UpA/viewform';
  const params = new URLSearchParams({
    'usp': 'pp_url',
    'entry.2130649886': searchTerm,
    'entry.1077233496': handles,
    ...(userEmail && { 'entry.1742283366': userEmail })
  });
  return `${baseUrl}?${params.toString()}`;
};

// Component to render list of calendars in the AI response
const CalendarsAnswer = memo(({ entry, messageIndex, searchTerm }) => {
  const { user } = useContext(AuthContext);
  const handles = useMemo(() => entry.calendars.map(cal => cal.handle).join(','), [entry.calendars]);
  const feedbackUrl = useMemo(() => createFeedbackUrl(searchTerm, handles, user?.email), 
    [searchTerm, handles, user?.email]);

  return (
    <>
      {!entry.text && <Typography variant="body1" sx={{ mt: .5, ml: 1.75 }}>Here are some popular calendars:</Typography>}
      <List sx={{ mt: 0, ml: 0, width: "100%" }}>
        {entry.calendars.map((calendar) => (
          <ListItem
            key={`${calendar._id}_${messageIndex}`}
            secondaryAction={<SubscribeButton calendar={calendar} isEventCard={true} />}
            sx={{ '& .MuiListItemSecondaryAction-root': { right: 0 }, pr: 0 }}
          >
            <ListItemAvatar>
              <CalendarAvatar calendar={calendar} />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Link
                  data-testid={`calendar-list-${calendar._id}`}
                  to={`/${calendar.handle}/${calendar._id}`}
                  component={RouterLink}
                  color="inherit"
                  underline="none"
                  variant="h5"
                >
                  {calendar.name}
                </Link>
              }
              secondary={formatSubscribers(calendar.subscribers) ? `${formatSubscribers(calendar.subscribers)}` : ''}
              secondaryTypographyProps={{ variant: "subtitle2" }}
              sx={{
                width: { xs: "40%", sm: "24ch" },
                flex: "none",
                '& h5, & h6': {
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }
              }}
            />
          </ListItem>
        ))}
      </List>
      
      {/* Feedback section */}
      <Box sx={{ mt: 2, ml: 1.75, display: 'flex', alignItems: 'center' }}>
        <Typography variant="body2" color="text.secondary">
          Can't find what you're looking for?{' '}
          <Link 
            href={feedbackUrl}
            target="_blank"
            rel="noopener noreferrer"
            sx={{ 
              textDecoration: 'underline',
              color: 'text.secondary',
              '&:hover': { textDecoration: 'none' },
              cursor: 'pointer'
            }}
          >
            Let us know
          </Link>
        </Typography>
      </Box>
    </>
  );
});

// Component to render the chat interface
const RenderChatInterface = memo(({ chatLog, introMessage, chips, handleChipClick, profileImage }) => {

  const messagesToRender = useMemo(() => {
    return chatLog.length > MAX_MESSAGES
      ? chatLog.slice(chatLog.length - MAX_MESSAGES)
      : chatLog;
  }, [chatLog]);

  return (
    <List sx={{ flexGrow: 1 }}>
      {/* Introductory message for the tab */}
      <ListItem sx={{ display: 'flex', alignItems: 'flex-start' }}>
        <Avatar alt="Alfie" size="small" src={alfie} sx={{ mt: 0.5 }} />
        <Box>
          <ListItemText
            sx={{ ml: 1.75, whiteSpace: 'pre-wrap', textWrap: 'balance' }}
            primary={introMessage}
            primaryTypographyProps={{ variant: "body1" }}
          />
          <Stack direction="row" sx={{ flexWrap: 'wrap', mt: 3 }}>
            {chips.map((chip, index) => (
              <Chip
                key={index}
                label={chip.label}
                onClick={() => handleChipClick(chip.message)}
                sx={{ ml: 1, mt: 1 }}
                color="primary"
                variant="outlined"
                size="small"
              />
            ))}
          </Stack>
        </Box>
      </ListItem>

      {/* Rest of messages - Chat log */}
      {messagesToRender.map((entry, index) => {
        // Find the user's search term from the previous message
        const searchTerm = entry.sender === 'ai' && index > 0 ? messagesToRender[index - 1].text : '';

        return (
          <ListItem key={index} sx={{ alignItems: 'flex-start', mt: 2 }}>
            {entry.sender === 'ai' ? (
              <Avatar alt="Alfie" size="small" src={alfie} sx={{ mt: 0.5 }} />
            ) : (
              <Avatar alt="User" size="small" src={profileImage} sx={{ mt: 0.5 }} />
            )}
            <Box sx={{ flex: "1 1 auto", maxWidth: "100%" }}>
              {entry.text && (
                <ListItemText
                  primary={entry.text}
                  sx={{ ml: 1.75, whiteSpace: 'pre-wrap', textWrap: 'balance' }}
                  primaryTypographyProps={{ variant: "body1" }}
                />
              )}
              {entry.sender === 'ai' && entry.calendars && entry.calendars.length > 0 && (
                <CalendarsAnswer 
                  entry={entry} 
                  messageIndex={index} 
                  searchTerm={searchTerm}
                />
              )}
              {entry.sender === 'ai' && entry.calendars && entry.calendars.length === 0 && (
                <ListItemText
                  primary="No calendars found. Please try searching for something else."
                  sx={{ ml: 1.75, whiteSpace: 'pre-wrap', textWrap: 'balance' }}
                  primaryTypographyProps={{ variant: "body1" }}
                />
              )}
            </Box>
          </ListItem>
        );
      })}
    </List>
  );
});

const Search = () => {
  const chatRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const isMounted = useIsMounted();
  const mounted = isMounted();
  const location = useLocation();
  const containerRef = useRef(null);
  useFullHeightPageFix(containerRef);
  const searchBarRef = useRef(null);

  useEffect(() => {
    searchBarRef.current?.focus();
  }, []);

  const initialAlfieSearch = {
    searchChatLog: [],
    activeTab: 'search'  // Keep only search
  };

  const [AlfiSearch, setAlfieSearch, statePropertyFunctions] = useIndexedDB('stanza', 'alfie-search', 'cache', initialAlfieSearch);
  const { searchChatLog } = AlfiSearch;
  const { setSearchChatLog } = statePropertyFunctions;
  const [message, setMessage] = useState('');
  const theme = useTheme();

  // Refs to keep track of the latest chat logs
  const searchChatLogRef = useRef(searchChatLog);

  // Update refs whenever chat logs change
  useEffect(() => {
    searchChatLogRef.current = searchChatLog;
  }, [searchChatLog]);

  const introMessages = useMemo(() => ({
    search: "Search for a calendar or choose from popular calendars on Stanza:"
  }), []);

  const tabChips = useMemo(() => ({
    search: [
      { label: "Warriors", message: "Golden State Warriors" },
      { label: "PGA", message: "PGA" },
      { label: "Reacher on Prime", message: "Reacher" },
      { label: "Boxing", message: "Boxing" }

    ]
  }), []);

  const { user } = useContext(AuthContext);

  const profileImage = useProfileImage(user);

  const handleSend = useCallback(async (inputMessage = message) => {
    if (inputMessage.trim()) {
      // Set loading to true
      setIsLoading(true);

      // Append user message to chat log
      setSearchChatLog(chatLog => [...chatLog, { sender: 'user', text: inputMessage.trim() }]);

      // Clear the input field
      setMessage('');

      try {
        const response = await axios.post(
          getApiRoute("chat", "SEARCH"),
          {
            message: inputMessage.trim()
          }
        );

        console.log(response.data.response);

        // Replace with your OpenAI response
        setSearchChatLog(prevLog => [...prevLog, { sender: 'ai', text: response.data.response, calendars: response.data.calendars }]);

      } catch (error) {
        console.error('Error querying OpenAI:', error.message);
        setSearchChatLog(prevLog => [...prevLog, { sender: 'ai', text: "I'm sorry, I'm having trouble processing your request. Please try again later." }]);
      } finally {
        setIsLoading(false);
      }
    }
  }, [message, setSearchChatLog]);

  const handleChipClick = useCallback((chipMessage) => {
    handleSend(chipMessage);
  }, [handleSend]);
  
  useEffect(() => {
    if (!mounted) return;
    if (location.state?.term) {
      handleSend(location.state.term);
    }
  }, [location.state?.term, mounted]);

  // Cleanup effect to trim chat logs to last MAX_MESSAGES on unmount
  useEffect(() => {
    return () => {
      // Trim searchChatLog
      if (searchChatLogRef.current.length > MAX_MESSAGES) {
        setSearchChatLog(searchChatLogRef.current.slice(-MAX_MESSAGES));
      }
    };
  }, [setSearchChatLog]);

  useEffect(() => {
    if (!mounted || !chatRef.current) return;
    
    // Scroll to bottom whenever chatLog updates or on initial render
    chatRef.current.scrollTo({
      top: chatRef.current.scrollHeight,
      behavior: 'smooth'
    });
  }, [searchChatLog, mounted]);

  return (
    <div ref={containerRef} style={{ display: 'flex', flexDirection: 'column', height: '100vh', overflow: 'hidden' }}>
      <Helmet>
        <title>Stanza - Search</title>
        <meta name="description" content="Search for calendars on Stanza." />
      </Helmet>
      <Navbar user={user} />
      <Box display="flex" flexDirection="column" sx={{ height: "100%", overflow: "hidden", margin: "auto", marginTop: '0px', maxWidth: { xs: '100%', sm: "600px" }, p: 0, flexGrow: 1, minWidth: { xs: '100%', sm: "600px" } }}>
        {/* Chat Interface */}
        <Box ref={chatRef} id="chat-interface" display="flex" flexDirection="column" sx={{ width: "100%", overflow: 'auto', flexGrow: 1 }}>
          <RenderChatInterface 
            chatLog={searchChatLog} 
            introMessage={introMessages.search} 
            chips={tabChips.search} 
            handleChipClick={handleChipClick} 
            profileImage={profileImage}
          />
        </Box>
        
        {/* Input Field and Send Button */}
        <div style={{ display: 'flex', padding: '10px', marginBottom: "4px" }}>
          <TextField
            inputRef={searchBarRef}
            fullWidth
            type="search"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={(e) => { if (e.key === 'Enter' && !isLoading) handleSend(); }}
            placeholder="Search for calendars"
            sx={{ background: `${theme.palette.paper.secondary}`, border: "none", py: "10px", pl: "10px", '& .MuiInputBase-root': { pr: "10px" }}}
            InputProps={{
              endAdornment: isLoading ? (
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "28px", height: "28px" }}>
                  <CircularProgress thickness={2} size={30} />
                </Box>
              ) : (
                <IconButton variant="contained" onClick={() => handleSend(message)} sx={{ ml: "10px", width: "40px", height: "40px" }}>
                  <Icon fontSize="medium" sx={{ color: 'secondary.main' }} baseClassName="material-symbols-outlined">search</Icon>
                </IconButton>
              ),
            }}
          />
        </div>
      </Box>
    </div>
  );
}

export default Search;