import {
  Box,
  Typography,
  Icon,
  CircularProgress,
  Collapse,
  Link,
} from "@mui/material";
import { Fragment, memo, useMemo, useContext } from "react";
import { useTheme } from "@emotion/react";
import { useDimensions } from "src/utils/useDimensions.js";
import RecommendedCalendar from "./event/RecommendedCalendar.js";
import { useIsMounted } from "src/utils/Utils.js";
import CalendarAddedSuccessfully from "./CalendarAddedSuccessfully.js";
import { useRecommendedCalendars } from "src/utils/useRecommendedCalendars.js";
import { AuthContext } from "src/utils/AuthContext.js";

const createFeedbackUrl = (sourceCalendar, recommendedHandles, userEmail) => {
  const baseUrl = 'https://docs.google.com/forms/d/e/1FAIpQLSfCe4DLadsG7GMS2BKjTYWJ5YfIboMZFz4NitREpX5aQrnPYQ/viewform';
  const params = new URLSearchParams({
    'usp': 'pp_url',
    'entry.670768737': sourceCalendar?.handle || '',
    'entry.2130649886': recommendedHandles,
    ...(userEmail && { 'entry.1742283366': userEmail })
  });
  return `${baseUrl}?${params.toString()}`;
};

const ContainerCollapse = ({ children, id, noRecommendedCalendars, sx }) => {
  const isMounted = useIsMounted();
  const mounted = isMounted();
  const theme = useTheme();
  return (
    <Collapse id={id} in={true} {...(mounted ? { timeout: "auto" } : {})}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          borderTop: `1px solid ${theme.palette.text.tertiary}`,
          ...(noRecommendedCalendars
            ? {
                borderBottom: `1px solid ${theme.palette.text.tertiary}`,
                pb: 4.5,
              }
            : {}),
          ...sx,
        }}
      >
        {children}
      </Box>
    </Collapse>
  );
};

const RecommendedCalendars = ({
  sourceCalendars,
  onSubscribeAction,
  displayMode = "added",
  sx,
}) => {
  const theme = useTheme();
  const { user } = useContext(AuthContext);
  const { isMediumScreen } = useDimensions();
  const sourceCalendar = useMemo(
    () => sourceCalendars[sourceCalendars.length - 1] ?? null,
    [sourceCalendars]
  );
  const { recommendedCalendars, isLoading, noRecommendedCalendars } =
    useRecommendedCalendars({ sourceCalendar });

  const recommendedHandles = useMemo(() => 
    recommendedCalendars?.map(rec => rec.calendar.handle).join(',') || '',
    [recommendedCalendars]
  );

  const feedbackUrl = useMemo(() => 
    createFeedbackUrl(sourceCalendar, recommendedHandles, user?.email),
    [sourceCalendar, recommendedHandles, user?.email]
  );

  if (!sourceCalendar) return null;

  return (
    <>
      <style>
        {`
        #recommended-calendars + div > #event-card {
          border-top: none;
        }
        #recommended-calendars + div {
          margin-top: 0px;
        }
        #recommended-calendars:first-of-type .MuiBox-root {
          border-top: none;
        }
      `}
      </style>
      <ContainerCollapse
        id="recommended-calendars"
        noRecommendedCalendars={noRecommendedCalendars}
        sx={sx}
      >
        {[
          "added",
          "addedViaGoogleConnection",
          "downloadGCalReminderConfirmation",
          "oneTimeDownloadConfirmation",
        ].includes(displayMode) && (
          <CalendarAddedSuccessfully
            displayMode={displayMode}
            sourceCalendar={sourceCalendar}
            sx={{ mb: !noRecommendedCalendars ? -1 : "-5px" }}
          />
        )}
        {isLoading && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "304px",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
            }}
          >
            <CircularProgress thickness={2} />
            <Typography variant="subtitle2">You might also like...</Typography>
          </Box>
        )}
        {displayMode === "related" && noRecommendedCalendars && !isLoading && (
          <Typography
            variant="subtitle2"
            sx={{ margin: "24px auto -8px auto" }}
          >
            No recommended calendars found. 
          </Typography>
        )}
        {!noRecommendedCalendars && !isLoading && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              py: 4.5,
              pl: 2,
              borderBottom: `1px solid ${theme.palette.text.tertiary}`,
            }}
          >
            {displayMode === "related" && (
              <Box
                sx={{ display: "flex", alignItems: "center", gap: 0.5, mb: 1 }}
              >
                <Icon
                  baseClassName="material-symbols-outlined"
                  sx={{
                    fontWeight: "300",
                    fontSize: "1.8rem",
                    color: "success.main",
                  }}
                >
                  check
                </Icon>
                <Typography variant="h2" data-testid="related-calendars-title">
                  You might like
                </Typography>
              </Box>
            )}
            <Box
              sx={{
                display: { xs: "flex", md: "grid" },
                alginItems: "center",
                mt: 1,
                gap: { xs: 2, md: "24px 16px" },
                flexDirection: { xs: "column" },
                gridTemplateColumns: { md: "1fr 1fr" },
              }}
            >
              {recommendedCalendars?.map((event, index) => (
                <Fragment key={`${event.calendarId}`}>
                  <RecommendedCalendar
                    calendar={event.calendar}
                    event={event.event}
                    calendarId={event.calendarId}
                    showUpload={false}
                    position={index}
                    onSubscribeAction={onSubscribeAction}
                  />
                  {isMediumScreen &&
                    index !== recommendedCalendars.length - 1 && (
                      <hr
                        style={{
                          width: "100%",
                          border: `0.5px solid ${theme.palette.text.tertiary}`,
                        }}
                      />
                    )}
                </Fragment>
              ))}
            </Box>
            
            {/* Add feedback section */}
            <Box sx={{ mt: 4, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Typography variant="body2" color="text.secondary">
                Recommendations feel off?{' '}
                <Link 
                  href={feedbackUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ 
                    textDecoration: 'underline',
                    color: 'text.secondary',
                    '&:hover': { textDecoration: 'none' },
                    cursor: 'pointer'
                  }}
                >
                  Help us improve
                </Link>
              </Typography>
            </Box>
          </Box>
        )}
      </ContainerCollapse>
    </>
  );
};

export default memo(RecommendedCalendars);
